import Step from "../../components/step/Step";
import {FormattedMessage, useIntl} from "react-intl";
import useStore from "../../storage/DataStore";
import {Field, Form, Formik} from "formik";
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon
} from "@chakra-ui/react";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import NextButton from "../../components/next-button/NextButton";
import {useEffect, useRef} from "react";

function Step3() {
    const intl = useIntl();

    const UserDataSchema = Yup.object().shape({
        bodyHeight: Yup.number()
            .required(intl.formatMessage({id: 'form.bodySize.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'}))
    });

    let navigate = useNavigate();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <Step heading={intl.formatMessage({id: 'step3.headline'})}
              currentStep={3}
              backLink={'/step2'}>
            <Box mt={5}>
                <Formik
                    initialValues={{
                        bodyHeight: useStore.getState().bodyHeight
                    }}
                    validationSchema={UserDataSchema}
                    onSubmit={(values, actions) => {
                        useStore.setState({
                            bodyHeight: values.bodyHeight
                        });
                        navigate('../step4', {replace: true});
                    }}
                >
                    {(props) => (
                        <Form>
                            <Field name={'bodyHeight'}>
                                {({field, form}) => (
                                    <FormControl isInvalid={form.errors.bodyHeight && form.touched.bodyHeight}>
                                        <FormLabel fontWeight={'bold'} htmlFor="bodyHeight">
                                            <FormattedMessage id="form.bodySize.label"/>
                                        </FormLabel>

                                        <InputGroup>
                                            <Input pattern={'[0-9]*'}
                                                   inputMode={'number'}
                                                   {...field}
                                                   id="bodyHeight"
                                                   ref={inputRef}/>

                                            <InputRightAddon background={'secondary'} color={'white'} borderColor={'secondary'} pointerEvents="none">
                                                cm
                                            </InputRightAddon>
                                        </InputGroup>

                                        <FormErrorMessage>{form.errors.bodyHeight}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <NextButton/>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Step>
    )
}

export default Step3;
