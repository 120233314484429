import Logo from '../../assets/home/logo.svg';
import {Box, Button, Container, Flex, Heading, Image, List, ListIcon, ListItem, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import AnswerOption from "../../components/answer-option/AnswerOption";
import {CheckCircleIcon} from "@chakra-ui/icons";
import SecureImage from "../../assets/home/secure.svg";
import useStore from "../../storage/DataStore";
import ConditionalContent from "../../components/condtional-content/ConditionalContent";

function Home({testMode, showLegal}) {
    let navigate = useNavigate();
    const intl = useIntl();
    const nextStep = function () {
        navigate('/step1', {replace: true});
    }

    const currentYear = new Date().getFullYear();

    const testPlan = function () {
        useStore.setState({
            gender: 'male',
            weight: 100,
            targetWeight: 80,
            bodyHeight: 180,
            age: '30'
        });

        navigate('/step8', {replace: true});
    }

    return (
        <>
            {showLegal &&
                <Box textAlign={'center'} background={'#e6e6e6'} fontSize={'10px'} p={2}>
                    {intl.formatMessage({id: 'legal-stuff.header'})}
                </Box>
            }

            {testMode &&
                <Flex justifyContent={'center'}>
                    <Button onClick={testPlan} bg={'red'} color={'white'} my={3}>
                        TEST
                    </Button>
                </Flex>
            }

            <Container>
                <Box textAlign={"center"}>
                    <Box textAlign={'center'} mt={5} pb={2}>
                        <Image height={8} mb={2} src={Logo} alt={'Indravil®'} className="logo" display={'inline-block'}/>
                    </Box>

                    <Heading mb={[0, 3]} fontSize={[22, 32]}>
                        <FormattedMessage id="home.heading"/>

                        <Box mt={5}>
                            <AnswerOption onClick={() => nextStep()}>
                                {intl.formatMessage({id: 'home.option1'})}
                            </AnswerOption>
                            <AnswerOption onClick={() => nextStep()}>
                                {intl.formatMessage({id: 'home.option2'})}
                            </AnswerOption>
                            <AnswerOption onClick={() => nextStep()}>
                                {intl.formatMessage({id: 'home.option3'})}
                            </AnswerOption>
                            <AnswerOption onClick={() => nextStep()}>
                                {intl.formatMessage({id: 'home.option4'})}
                            </AnswerOption>
                            <AnswerOption onClick={() => nextStep()}>
                                {intl.formatMessage({id: 'home.option5'})}
                            </AnswerOption>
                        </Box>
                    </Heading>
                </Box>

                <ConditionalContent condition="bh">
                    BH!
                </ConditionalContent>

                <ConditionalContent condition="wh">
                    WH!
                </ConditionalContent>

                {showLegal &&
                    <Box mt={10}>
                        <List spacing={5}>
                            <ListItem>
                                <Flex>
                                    <ListIcon mr={4} boxSize={[7, 8]} as={CheckCircleIcon} color='#013432'/>
                                    {intl.formatMessage({id: 'legal-stuff.fact1'})}
                                </Flex>
                            </ListItem>
                            <ListItem>
                                <Flex>
                                    <ListIcon mr={4} boxSize={[7, 8]} as={CheckCircleIcon} color='#013432'/>
                                    {intl.formatMessage({id: 'legal-stuff.fact2'})}
                                </Flex>
                            </ListItem>
                            <ListItem>
                                <Flex>
                                    <ListIcon mr={4} boxSize={[7, 8]} as={CheckCircleIcon} color='#013432'/>
                                    {intl.formatMessage({id: 'legal-stuff.fact3'})}
                                </Flex>
                            </ListItem>
                            <ListItem>
                                <Flex>
                                    <ListIcon mr={4} boxSize={[7, 8]} as={CheckCircleIcon} color='#013432'/>
                                    {intl.formatMessage({id: 'legal-stuff.fact4'})}
                                </Flex>
                            </ListItem>
                        </List>
                    </Box>
                }
            </Container>

            {showLegal &&
                <Box borderTop={5}
                     borderStyle={'solid'}
                     py={10}
                     borderColor={'#013432'}
                     mt={10}>
                    <Flex justifyContent='center'>
                        <Box width={['100%', '70%']}>
                            <Flex direction={{base: "column", md: "row"}}
                                  alignItems="end">
                                <Box flex={{base: "1", md: "7"}}
                                     pr={[0, 10]}
                                     px={[10, 0]}
                                     w="100%"
                                     textAlign={['center', 'left']}>
                                    <Image height={10}
                                           src={Logo}
                                           alt={'Indravil®'}
                                           className="logo"
                                           mb={5}
                                           display={'inline'}/>
                                    <Text>
                                        {intl.formatMessage({id: 'legal-stuff.footer'})}
                                    </Text>
                                </Box>
                                <Box flex={{base: "1", md: "3"}}
                                     fontSize={14}
                                     w="100%"
                                     textAlign={['center', 'right']}
                                     mt={[5, 0]}>
                                    <Image mb={3} src={SecureImage} display={'inline'}/>

                                    <Text>Copyright © {currentYear} Indravil - All Rights Reserved.</Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            }
        </>
    );
}

export default Home;