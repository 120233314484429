import {Button} from "@chakra-ui/react";
import './CtaButton.scss';

function CtaButton(props) {
    const {text, link} = props;

    const handleClick = () => {
        window.location.href = link;
    };

    return (
        <Button background={'cta'}
                textColor={'white'}
                p={7}
                fontSize={'xl'}
                className={'pulse'}
                width={['100%', '50%']}
                _hover={{
                    bg: "black",
                    color: "white",
                }}
                onClick={handleClick}>
            {text}
        </Button>
    )
}

export default CtaButton;