import { Button } from "@chakra-ui/react";

function AnswerOption(props) {
    const { onClick, children } = props;

    return (
        <Button width={"100%"}
                mb={3}
                p={8}
                background={"secondary"}
                color={"white"}
                _hover={{
                    bg: "#f8d10d",
                    color: "black",
                    borderColor: "brand"
                }}
                _checked={{
                    bg: "#f8d10d",
                    color: "black",
                }}
                onClick={onClick}>
            {children}
        </Button>
    );
}

export default AnswerOption;
