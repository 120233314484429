import {useEffect, useState} from 'react';

function ConditionalContent({condition, children}) {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        // Event handler function for the cookler event
        const handleCooklerEvent = (e) => {
            const isWH = e.detail.wh;
            const shouldRender = (condition === 'bh' && !isWH) || (condition === 'wh' && isWH);
            setShouldRender(shouldRender);
        };

        // Add event listener for cookler event
        document.addEventListener('cookler', handleCooklerEvent);

        // Clean up event listener when component unmounts or dependencies change
        return () => {
            document.removeEventListener('cookler', handleCooklerEvent);
        };
    }, [condition]); // Re-run effect when condition or isBh changes

    return shouldRender ? <>{children}</> : null;
}

export default ConditionalContent;
