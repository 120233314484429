import "./Indravil.scss";
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400.css'

function IndravilTemplate(props) {
    return (
        <div className={"page indravil"}>
            {props.children}
        </div>
    );
}

export default IndravilTemplate;
