import {Button} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";

function NextButton(props) {
    let navigate = useNavigate();

    const handleClick = () => {
        if (props.redirect) {
            navigate('../' + props.redirect, {replace: true});
        }
    };

    return (
        <Button bg="secondary"
                color={"white"}
                _hover={{
                    bg: "#f8d10d",
                    color: "black",
                }}
                size="md"
                mt={5}
                p={5}
                w={'100%'}
                type="submit"
                onClick={handleClick}>
            <FormattedMessage id="form.button.next"/>
        </Button>
    )
}

export default NextButton;
